.screen-too-small {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .screen-too-small {
        display: block;
    }
    .main-contents {
        display: none;
    }
}
